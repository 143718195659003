import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import SectionCampaignCondition from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignCondition';

const DATA = [
  {
    title: <strong>キャンペーン概要</strong>,
    content: (
      <>
        キャンペーン期間中に初めてペイディを利用すると、3,000円キャッシュバック。
        <br />
        <br />
        <small>
          ※キャンペーン期間中に上記条件で利用された方を対象に11月末にキャッシュバックを実施。11月30日ごろにペイディアプリおよびMyPaidyに反映されます。また、決済画面には反映されませんのでご注意ください。
        </small>
        <br />
        <small>
          ※キャンペーン期間中のお買い物金額に関しては、10月末締で全額を11月10日までにお支払いいただく必要があります。キャッシュバックは、11月1日以降に再度ペイディをご利用いただいた場合のご請求金額に適用させていただきますので、予めご了承ください。11月末締の請求金額がキャッシュバック金額に満たない場合、12月末締以降のご請求から差し引かれます。
        </small>
      </>
    ),
  },
  {
    title: <strong>キャンペーン期間</strong>,
    content: '2022年10月11日（火） 0:00 ~ 10月31日 (月) 23:59',
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・本キャンペーンはキャンペーンメールを受信された方、もしくはペイパルのマイアカウントでバナーが表示された方限定のキャンペーンです。
        <br />
        ・キャッシュバックは、2022年10月11日～2022年10月31日の間にペイディをはじめてご利用された方限定です。
        <br />
        ・キャッシュバックの金額は、お一人様3,000円です。
        <br />
        ・キャッシュバックはお一人様一回までとさせていただきます。
        <br />
        ・ペイパルにご登録のメールアドレスと同一のメールアドレスで作成されたアカウントのみ対象となります。
        <br />
        ・キャッシュバックは、2022年11月30日頃にMyPaidyもしくはペイディアプリに反映され、11月末締めの請求額より差し引かれます。
        <br />
        ・キャンペーン期間中のお買い物金額に関しては、10月末締で全額を11月10日までにお支払いいただく必要があります。キャッシュバックは、11月1日以降に再度ペイディをご利用いただいた場合のご請求金額に適用させていただきますので、予めご了承ください。
        <br />
        ・11月末締の請求金額がキャッシュバック金額に満たない場合、12月末締以降のご請求から差し引かれます。
        <br />
        ・キャッシュバックは、ペイディのご利用に応じた請求金額より差し引く方法により付与されます。いかなる場合でも、キャッシュバック金額を現金で交付することはできませんのでご了承ください。
        <br />
        ・Amazonでのお買い物の場合、期間中に発送（出荷）された商品の総額がキャンペーン対象となります。Amazon
        Primeのお申し込み、Amazon
        Musicの定額ストリーミングサービスのプラン料金など期間中に決済の発生しないサービスの支払いはキャッシュバックの対象外となります。キャンセルされた商品は、キャッシュバックの対象外となります。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・不正と判断された場合や購入のキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件は
        <OutboundLink
          href="https://terms.paidy.com/cashback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          キャッシュバック利用規約
        </OutboundLink>
        に準じます。
        <br />
        ・ペイディに関するお問い合わせは
        <OutboundLink
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディヘルプページ
        </OutboundLink>
        へご連絡ください。
      </>
    ),
  },
];

export default function JulyCondition() {
  return <SectionCampaignCondition data={DATA} />;
}
