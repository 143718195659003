import React from 'react';
import Layout from 'src/components/Layout/Layout';
import JulyPayLater from 'src/components/Merchant/Campaign/NewCashbackOctober/JulyPayLater';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/NewCashbackOctober/JulyPaymentSteps';
import JulyCondition from 'src/components/Merchant/Campaign/NewCashbackOctober/JulyConditions';
import JulyAppDownload from 'src/components/Merchant/Campaign/NewCashbackOctober/JulyAppDownload';
import JulyWhyPaidy from 'src/components/Merchant/Campaign/NewCashbackOctober/JulyWhyPaidy';
import JulyTopBanner from 'src/components/Merchant/Campaign/NewCashbackOctober/JulyTopBanner';
import { WhereToShop } from 'src/components';

const NewCashbackOctober = () => {
  return (
    <Layout
      SEOProps={{
        noSeo: true,
      }}
      hasNoHeader
      hasNoFooter
    >
      <JulyTopBanner />
      <JulyPayLater />
      <JulyPaymentSteps />
      <WhereToShop
        limit={8}
        seeMoreText="使えるお店の詳細を見る"
        style={{
          background: '#F0F0F0',
        }}
        title="AmazonやQoo10のほかネットショップならどこでも"
        description="ペイディカードを発行するとVisaマークのあるネットショップで利用可能"
      />
      <JulyCondition />
      <JulyWhyPaidy />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
};

export default NewCashbackOctober;
